/*
<--!----------------------------------------------------------------!-->
<--! Offcanvas !-->
<--!----------------------------------------------------------------!-->
*/
.offcanvas {
	&.offcanvas-end {
		border-color: $border-color !important;
	}
}
