/*
<--!----------------------------------------------------------------!-->
<--! dataTables !-->
<--!----------------------------------------------------------------!-->
*/
.dataTables_wrapper {
	.row {
		&:first-child,
		&:last-child {
			padding: 25px 25px;
		}
		&:first-child {
			border-bottom: 1px solid $border-color-2;
		}
		&:last-child {
			border-top: 1px solid $border-color-2;
		}
	}
}
.dataTables_paginate {
	.page-link,
	.active > .page-link,
	.page-link.active {
		border-radius: $radius-sm;
	}
}
