/*
<--!----------------------------------------------------------------!-->
<--! Select2 !-->
<--!----------------------------------------------------------------!-->
*/
.select2-container {
	width: -webkit-fill-available !important;
	.select2-selection {
		color: $brand-dark;
		font-size: $font-13;
		padding-bottom: 0;
		border-color: $border-color;
		min-height: calc(2.5em + 0.75rem + 2px);
	}
	.select2-selection--single {
		background-size: 15px 8px;
		padding: 0.475rem 2.25rem 0.375rem 0.75rem;
		.select2-selection__rendered {
			color: $brand-dark;
			padding: 5px;
			width: 100%;
			max-width: 100%;
			overflow: hidden;
			font-weight: $font-500;
			/*
			text-overflow: ellipsis;
			white-space: initial;
			display: -webkit-box !important;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical; 
			*/
		}
	}
	&.select2-container--focus,
	&.select2-container--open {
		.select2-selection {
			box-shadow: none;
			border-color: $border-color;
		}
	}
	.select2-dropdown {
		z-index: 999999;
		color: $brand-dark;
		border-color: $border-color;
		border-radius: $radius-md;
		box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
		.select2-results__options {
			.select2-results__option {
				color: $brand-dark;
				font-size: $font-13;
				padding: 10px 25px;
				font-weight: $font-500;
				&.select2-results__option--selected,
				&.select2-results__option--highlighted {
					color: $primary;
					background-color: rgba(84, 133, 228, 0.075);
				}
			}
		}
		.select2-search {
			.select2-search__field {
				color: $brand-dark;
				font-size: $font-13;
				border-color: $border-color;
			}
		}
	}
	.select2-selection--multiple {
		.select2-selection__rendered {
			.select2-selection__choice {
				position: relative;
				color: $brand-dark;
				font-size: $font-13;
				font-weight: $font-600;
				padding: 8px 30px 8px 15px;
				border-color: $border-color;
				.select2-selection__choice__remove {
					right: 5px;
					width: 7px;
					height: 7px;
					margin-right: 5px;
					position: absolute;
				}
			}
		}
	}
	.select2-selection--multiple {
		.select2-search {
			height: 0;
			.select2-search__field {
				height: 0;
			}
		}
	}
}
.select-wd-sm {
	.select2-container {
		width: 50px !important;
	}
}
.select-wd-md {
	.select2-container {
		width: 125px !important;
	}
}
.select-wd-lg {
	.select2-container {
		width: 225px !important;
	}
}
.select-wd-xl {
	.select2-container {
		width: 350px !important;
	}
}
.select-wd-xxl {
	.select2-container {
		width: 500px !important;
	}
}
