/*
<--!----------------------------------------------------------------!-->
<--! Tables !-->
<--!----------------------------------------------------------------!-->
*/
.table-responsive {
	.table {
		color: $brand-dark;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		border-color: $border-color;
		vertical-align: middle;
		&:not(caption) > * {
			border-color: $border-color !important;
		}
		thead {
			th {
				/* border-top: 1px solid $border-color; */
				border-bottom: 1px solid $border-color;
			}
		}
		thead,
		tfoot {
			th {
				padding: 8px 15px;
				color: $brand-dark;
				font-size: $font-10;
				font-weight: $font-700;
				text-transform: $font-uppercase;
				&:first-child {
					padding-left: 30px;
				}
				&:last-child {
					padding-right: 30px;
				}
			}
		}
		tbody {
			tr {
				&:last-child * {
					border-bottom: transparent;
				}
			}
		}
		tr {
			td {
				padding: 15px 15px;
				white-space: nowrap;
				vertical-align: middle;
				&:first-child {
					padding-left: 30px;
				}
				&:last-child {
					padding-right: 30px;
				}
			}
		}
		&.table-dark {
			border-color: $black !important;
			tr,
			td,
			th {
				color: $gray-600;
				border-color: $dark;
			}
		}
		&.table-striped > tbody > tr:nth-of-type(odd) > *,
		&.table-hover > tbody > tr:hover > * {
			--bs-table-accent-bg: rgb(244 244 249);
		}
	}
	table.dataTable {
		&.table-hover {
			> tbody > tr:hover > * {
				box-shadow: none;
				transition: all 0.3s ease;
				background-color: lighten($gray-100, 1);
			}
		}
		tbody {
			> tr {
				&.selected > * {
					color: $dark;
					box-shadow: inset 0 0 0 #eeeef5;
					a {
						color: $dark;
					}
				}
			}
		}
	}
	div.dataTables_wrapper {
		div.dataTables_filter,
		div.dataTables_length {
			input,
			select {
				padding: 8px 15px;
			}
		}
	}
	.dataTables_paginate {
		.page-link {
			color: $dark;
			font-size: $font-12;
			box-shadow: none !important;
			border-color: darken($gray-100, 1);
		}
		.active > .page-link,
		.page-link.active {
			color: $white !important;
			background-color: $primary;
		}
		.disabled > .page-link,
		.page-link.disabled {
			color: $gray-400;
		}
	}
	div.dataTables_info {
		font-size: $font-11;
	}
}
