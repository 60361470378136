/*
<--!----------------------------------------------------------------!-->
<--! Projects !-->
<--!----------------------------------------------------------------!-->
*/
#projectList {
	.single-item {
		&:hover {
			.project-name-td {
				.project-list-action {
					opacity: 1;
					visibility: visible;
					transition: all 0.3s ease;
				}
				.project-list-desc {
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s ease;
				}
			}
		}
		.project-name-td {
			width: 400px;
			white-space: normal;
			.project-list-action {
				opacity: 0;
				visibility: hidden;
				position: relative;
				top: -40px;
				padding-top: 5px;
				margin-bottom: -35px;
				background-color: transparent;
				transition: all 0.3s ease;
			}
			.tickets-list-action {
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease;
			}
			.project-list-desc {
				opacity: 1;
				visibility: visible;
				transition: all 0.3s ease;
			}
		}
	}
}
