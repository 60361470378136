/*
<--!----------------------------------------------------------------!-->
<--! Widgets Charts !-->
<--!----------------------------------------------------------------!-->
*/
#earning-expense-area-chart {
	.apexcharts-series[seriesName="Expense"] {
		transform: scaleY(1.025);
	}
}
#session-device-donut {
	.apexcharts-canvas {
		margin: auto;
	}
}
