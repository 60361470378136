/*
<--!----------------------------------------------------------------!-->
<--! Proposal !-->
<--!----------------------------------------------------------------!-->
*/
.progress-1,
.progress-2,
.progress-3,
.progress-4 {
	.circle-progress-value {
		stroke-width: 6px;
		stroke-linecap: round;
	}
	.circle-progress-circle {
		stroke-width: 3px;
	}
}
.progress-1 {
	.circle-progress-value {
		stroke: $primary;
	}
	.circle-progress-circle {
		stroke: lighten($primary, 42);
	}
}
.progress-2 {
	.circle-progress-value {
		stroke: $success;
	}
	.circle-progress-circle {
		stroke: lighten($success, 45);
	}
}
.progress-3 {
	.circle-progress-value {
		stroke: $warning;
	}
	.circle-progress-circle {
		stroke: lighten($warning, 35);
	}
}
.progress-4 {
	.circle-progress-value {
		stroke: $danger;
	}
	.circle-progress-circle {
		stroke: lighten($danger, 35);
	}
}
.proposal-action-btn {
	display: flex;
	.action-btn {
		height: 60px;
		padding: 15px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-left: 1px solid $border-color;
		&.action-btn-right-border {
			padding-right: 0;
		}
		i {
			font-size: $font-15;
		}
	}
}
.proposal-faq-accordion {
	&.accordion {
		.accordion-item {
			margin-bottom: 15px;
			border-top: 1px solid $border-color;
			.accordion-header {
				.accordion-button {
					padding: 25px 25px;
				}
			}
		}
	}
}
.task-info {
	.task-info-list {
		.task-list-row {
			.avatar-text {
				display: none;
			}
			&:hover {
				.avatar-text {
					display: flex;
				}
			}
		}
	}
}

/* checklist */
ul#checklist {
	li {
		cursor: pointer;
		position: relative;
		font-size: $font-13;
		margin-bottom: 10px;
		font-weight: $font-500;
		border-radius: $radius-sm;
		padding: 12px 15px 12px 15px;
		border: 1px dashed $border-color;
		transition: all 0.3s ease;
		&.checked {
			color: $gray-400;
			font-style: italic;
			padding: 12px 15px 12px 45px;
			background-color: lighten($gray-100, 1);
			text-decoration: line-through;
			transition: all 0.3s ease;
			&:before {
				top: 12px;
				left: 20px;
				width: 6px;
				height: 12px;
				content: "";
				position: absolute;
				border-style: solid;
				border-color: $primary;
				transform: rotate(45deg);
				border-width: 0 1px 1px 0;
			}
		}
		.close {
			top: 0;
			right: 0;
			color: $danger;
			position: absolute;
			padding: 12px 16px 12px 16px;
			border-left: 1px dashed $border-color;
			&:hover {
				color: $danger;
				background-color: $bg-soft-danger;
				transition: all 0.3s ease;
			}
		}
	}
}
/* End checklist */
