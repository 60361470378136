/*
<--!----------------------------------------------------------------!-->
<--! Pacejs SCSS Start !-->
<--!----------------------------------------------------------------!-->
*/
.pace {
	user-select: none;
	pointer-events: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	top: 0;
	right: 100%;
	width: 100%;
	height: 3px;
	z-index: 2000;
	position: fixed;
	background: #3454d1;
}
