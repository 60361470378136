/*
<--!----------------------------------------------------------------!-->
<--! Dashboard !-->
<--!----------------------------------------------------------------!-->
*/
#leads-overview-donut {
	.apexcharts-canvas {
		margin: auto;
	}
}
