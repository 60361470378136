/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 320px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 320px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 375px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 375px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 576px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 576px) {
	.nxl-user-dropdown {
		.dropdown-menu.show {
			display: block;
			left: -225px !important;
			top: -10px !important;
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 768px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 768px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 992px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 992px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 1024px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 1024px) {
	.nxl-head-mobile-toggler {
		display: none;
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 1200px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 1200px) {
	.nxl-container {
		&.apps-container {
			.nxl-content {
				&.without-header {
					.main-content {
						.app-sidebar-open-trigger,
						.app-sidebar-close-trigger {
							display: none !important ;
						}
					}
				}
			}
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 1400px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 1400px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 1600px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 1600px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 319.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 319.98px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 374.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 374.98px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 424.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 424.98px) {
	/*auth*/
	.auth-container {
		.wd-450 {
			width: 350px !important;
			min-width: 350px !important;
			max-width: 350px !important;
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 575.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 575.98px) {
	.nxl-header {
		.header-wrapper {
			padding: 0 20px;
			.nxl-h-item {
				position: static;
				.nxl-h-dropdown {
					left: 0 !important;
					right: 0 !important;
					width: -webkit-fill-available;
				}
			}
			.nxl-level-menu {
				.dropdown-menu {
					left: 0;
					display: block;
					padding-left: 30px;
				}
			}
		}
	}
	.nxl-container {
		.nxl-content {
			.page-header {
				padding: 0 20px;
				/* 
				.page-header-title {
					display: none;
				} 
				*/
			}
			.main-content {
				padding: 20px 20px 5px;
				.content-area {
					.content-area-header,
					.content-area-body {
						padding: 20px 20px;
					}
				}
			}
		}
	}
	/*general*/
	.card {
		margin-bottom: 20px;
	}
	/*plugins*/
	.tui-full-calendar-popup {
		.tui-full-calendar-popup-container {
			min-width: 300px;
			.tui-full-calendar-section-calendar {
				width: 130px;
				.tui-full-calendar-dropdown-button .tui-full-calendar-content,
				.tui-full-calendar-section-state .tui-full-calendar-content {
					width: 85px !important;
				}
			}
			.tui-full-calendar-section-state {
				width: 145px !important;
			}
			.tui-full-calendar-dropdown-button.tui-full-calendar-popup-section-item {
				width: 140px !important;
			}
			.tui-full-calendar-section-title {
				width: calc(100% - 55px) !important;
			}
			.tui-full-calendar-section-title input {
				width: 185px !important;
			}
			.tui-full-calendar-popup-section-item.tui-full-calendar-section-location input {
				width: 240px !important;
			}
			.tui-full-calendar-section-end-date,
			.tui-full-calendar-section-start-date {
				width: 110px !important;
			}
			.tui-full-calendar-section-end-date input,
			.tui-full-calendar-section-start-date input {
				width: 68px !important;
			}
			.tui-full-calendar-popup-section-item .tui-full-calendar-section-allday {
				margin-left: 10px;
				padding: 5px 12px 5px 12px;
			}
		}
	}
	/*auth*/
	.auth-container {
		.card {
			margin: 20px auto;
			&.wd-450 {
				width: 400px;
				min-width: 400px;
				max-width: 400px;
			}
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 767.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 767.98px) {
	.nxl-header {
		.header-wrapper {
			.nxl-h-item {
				position: static;
				.nxl-h-dropdown {
					&.nxl-search-dropdown,
					&.nxl-language-dropdown {
						left: 0 !important;
						right: 0 !important;
						width: -webkit-fill-available;
					}
				}
			}
		}
	}
	.nxl-container {
		.nxl-content {
			.page-header {
				.page-header-right {
					.page-header-right-items {
						top: 79px;
						right: 0px;
						z-index: 99;
						height: 100%;
						width: 280px;
						position: fixed;
						background-color: $white;
						border-top: 1px solid $border-color;
						border-left: 1px solid $border-color;
						display: flex;
						flex-direction: column;
						opacity: 0;
						visibility: hidden;
						transition: all 0.3s ease;
						transform: translateX(100%);
						&.page-header-right-open {
							opacity: 1;
							visibility: visible;
							transition: all 0.3s ease;
							transform: translateX(0%);
							box-shadow: $shadow-lg;
							.btn,
							.dropdown,
							.reportrange-picker,
							.reportrange-picker-field {
								width: 100%;
							}
							.dropdown {
								.dropdown-menu {
									width: 240px;
									transform: translate3d(0px, 40px, 0px) !important;
								}
							}
						}
						.page-header-right-items-wrapper {
							padding: 20px 20px;
							flex-direction: column;
						}
						.page-header-right-close-toggle {
							height: 65px;
							width: 100%;
							padding: 15px;
							display: flex;
							align-items: center;
							line-height: 0;
							border-bottom: 1px solid $border-color;
						}
					}
				}
			}
			.page-content-left-navigation-wrapper {
				left: 0;
				top: 80px;
				z-index: 98;
				height: 100%;
				width: 250px;
				display: block;
				position: fixed;
				background: $white;
				border-right: 1px solid $border-color;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease;
				transform: translateX(-100%);
				&.page-content-left-open {
					opacity: 1;
					visibility: visible;
					transition: all 0.3s ease;
					transform: translateX(0);
				}
				.page-content-left-close-toggle {
					height: 65px;
					width: 100%;
					padding: 15px;
					display: flex;
					align-items: center;
					line-height: 0;
					border-bottom: 1px solid $border-color;
				}
			}
			.page-content-right-navigation-wrapper {
				right: 0;
				top: 80px;
				z-index: 98;
				height: 100%;
				width: 250px;
				display: block;
				position: fixed;
				background: $white;
				border-left: 1px solid $border-color;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease;
				transform: translateX(100%);
				&.page-content-right-open {
					opacity: 1;
					visibility: visible;
					transition: all 0.3s ease;
					transform: translateX(0);
				}
				.page-content-right-close-toggle {
					height: 65px;
					width: 100%;
					padding: 15px;
					display: flex;
					align-items: center;
					line-height: 0;
					border-bottom: 1px solid $border-color;
				}
			}
			.help-center-content-header {
				height: 350px !important;
				min-height: 350px !important;
				max-height: 350px !important;
			}
			.page-content-left-navigation-wrapper {
				.nav-tabs-custom-style {
					width: 100%;
					margin-top: -1px;
					flex-direction: column;
					.nav-item {
						.nav-link {
							width: 100%;
							text-align: left;
							padding: 15px 25px;
							border-right: none;
							border-left: 3px solid transparent;
							border-bottom: 1px solid $border-color;
							&.active,
							&:hover {
								border-bottom: 0;
								border-left: 3px solid $primary !important;
							}
						}
					}
				}
			}
			.page-content-right-navigation-wrapper {
				.proposal-action-btn {
					display: flex;
					flex-direction: column;
					padding: 20px 20px;
					.action-btn {
						height: auto;
						margin-bottom: 5px;
						padding: 10px 20px;
						align-items: center;
						justify-content: flex-start;
						border: 1px solid $border-color;
						border-radius: $radius-sm;

						&.action-btn-right-border {
							padding-right: 0;
						}
						i {
							font-size: $font-15;
						}
					}
				}
			}
			.main-content {
				.content-area {
					.content-area-body {
						.wd-300,
						.wd-350,
						.wd-400,
						.wd-450,
						.wd-500,
						.wd-550,
						.wd-600 {
							width: 100%;
							max-width: 100%;
							min-width: 100%;
						}
					}
				}
			}
		}
	}

	/*general*/
	.wd-300,
	.wd-350,
	.wd-400,
	.wd-450,
	.wd-500,
	.wd-550,
	.wd-600 {
		width: 100%;
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 991.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 991.98px) {
	.nxl-header {
		.header-wrapper {
			.header-left {
				.nxl-lavel-mega-menu {
					top: 0;
					left: 0;
					z-index: 1;
					width: 300px;
					height: 100%;
					position: fixed;
					overflow-y: auto;
					overflow-x: hidden;
					background: $white;
					box-shadow: $shadow-lg;
					border-right: 1px solid $border-color;
					opacity: 0;
					visibility: hidden;
					transform: translateX(-100%);
					transition: all 0.3s ease;
					.nxl-lavel-mega-menu-toggle {
						a#nxl-lavel-mega-menu-hide {
							width: 100%;
							height: 80px;
							padding: 25px;
							display: flex;
							align-items: center;
							border-bottom: 1px solid $border-color;
						}
					}
					.nxl-lavel-mega-menu-wrapper {
						padding: 25px 25px;
						flex-direction: column;
						.nxl-mega-menu {
							.nxl-h-dropdown {
								display: block;
								width: 100%;
								top: 80px !important;
								border: none;
								box-shadow: none;
								left: 0 !important;
								.nxl-mega-menu-tabs {
									width: 100%;
									min-width: 100%;
									max-width: 100%;
								}
								.nxl-mega-menu-tabs-content {
									height: auto;
									overflow-y: auto;
									border-left: none;
									border-top: 1px solid $border-color;
									.nxl-mega-menu-miscellaneous {
										.nxl-mega-menu-miscellaneous-tabs {
											border: none;
											.nav-item {
												.nav-link {
													width: 100%;
													border-top: none;
													border-right: none;
													border-bottom: none;
													border-left: 1px solid transparent;
													border-radius: $radius-none;
													&:first-child {
														margin-left: 0;
													}
													&.active {
														border-bottom: none;
														border-left: 1px solid $primary;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	html {
		&.nxl-lavel-mega-menu-open {
			.nxl-header {
				.header-wrapper {
					.header-left {
						.nxl-lavel-mega-menu {
							opacity: 1;
							visibility: visible;
							transform: translateX(0);
							transition: all 0.3s ease;
							.nxl-lavel-menu {
								display: inline;
							}
							.nxl-mega-menu {
								display: inline;
							}
						}
					}
				}
			}
		}
	}
	/*auth-cover*/
	.auth-cover-wrapper {
		.auth-cover-content-inner {
			display: none;
		}
		.auth-cover-sidebar-inner {
			padding: 25px 25px;
			margin: 30px 30px;
			border-radius: $radius-lg;
			.auth-cover-card-wrapper {
				.auth-cover-card {
					padding: 25px 0;
					width: 100%;
					max-width: 300px;
				}
			}
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 1023.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 1023.98px) {
	#tasksDetailsOffcanvas {
		&.w-50 {
			width: 100% !important;
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 1024px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 1024px) {
	.nxl-header {
		top: 0;
		left: 0 !important;
		transition: all 0.15s ease-in-out;
		.nxl-head-link {
			display: inline-flex;
			&#mobile-collapse {
				position: absolute;
				left: 15px;
			}
		}
		.nxl-head-mobile-toggler {
			display: inline-flex;
		}
		.nxl-navigation-toggle {
			display: none;
		}
		&.mob-header-active {
			top: $header-height;
			.header-wrapper {
				background: $header-background;
				position: relative;
				z-index: 5;
			}
		}
		.nxl-md-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			z-index: 1;
			background: rgba(0, 0, 0, 0.2);
		}
		.nxl-mega-menu .nxl-mega-dmenu .row.g-0 > .col {
			flex: 100%;
		}
	}
	.nxl-navigation {
		left: -#{$navigation-width};
		box-shadow: none;
		transition: all 0.15s ease-in-out;
		&.mob-navigation-active {
			left: 0 !important;
			.navbar-wrapper {
				position: relative;
				z-index: 5;
				background: inherit;
			}
		}
	}
	.minimenu {
		.nxl-header {
			left: 0 !important;
		}
		.nxl-container {
			margin-left: 0;
		}
	}
	.nxl-container {
		.nxl-content {
			.help-center-content-header {
				.search-form {
					width: 75% !important;
				}
			}
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 1199.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 1199.98px) {
	.nxl-header {
		.header-wrapper {
			.header-left {
				.nxl-mega-menu {
					.nxl-h-dropdown {
						width: auto;
						left: 30px !important;
						right: 30px !important;
						.nxl-mega-menu-tabs-content {
							height: 330px;
							overflow-y: scroll;
						}
					}
				}
			}
		}
	}
	.nxl-container {
		margin-left: 0px;
		&.apps-container {
			.nxl-content {
				&.without-header {
					.main-content {
						.content-sidebar {
							z-index: 1025;
							position: absolute;
							opacity: 0;
							visibility: hidden;
							transition: all 0.3s ease;
							transform: translateX(-100%);
							&.app-sidebar-open {
								opacity: 1;
								visibility: visible;
								transition: all 0.3s ease;
								transform: translateX(0);
								box-shadow: $shadow-lg;
							}
						}
					}
				}
			}
		}
	}
	/*auth-cover*/
	.auth-cover-wrapper {
		.auth-cover-content-inner {
			max-width: calc(100% - 450px);
		}
		.auth-cover-sidebar-inner {
			max-width: 450px;
			.auth-cover-card-wrapper {
				.auth-cover-card {
					padding: 25px 0;
					width: 100%;
					max-width: 450px;
				}
			}
		}
	}
	/*auth-creative*/
	.auth-creative-wrapper {
		.auth-creative-inner {
			.creative-card-wrapper {
				max-width: calc(100% - 60px);
			}
		}
	}
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 1399.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 1399.98px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (max-width: 1599.98px) !-->
<--!----------------------------------------------------------------!-->
*/
@media (max-width: 1599.98px) {
}

/*
<--!----------------------------------------------------------------!-->
<--! @media (min-width: 1200px) and (max-width: 1600px)  !-->
<--!----------------------------------------------------------------!-->
*/
@media (min-width: 1200px) and (max-width: 1600px) {
}
